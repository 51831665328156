<template>
  <div>
    <h1 class="title has-text-white">{{ $t("login.login") }}</h1>
    <form class="box" action="">
      <div class="field username">
        <p class="control has-icons-left">
          <input
            tabindex="1"
            :class="['input', userInputClass]"
            type="email"
            v-model="username"
            :placeholder="$t('login.email')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </p>
      </div>
      <div class="field password is-relative">
        <p class="control has-icons-left">
          <input
            tabindex="2"
            :class="['input', passwordInputClass]"
            type="password"
            v-model="password"
            :placeholder="$t('login.password')"
            @focus="isForgotVisible = false"
            @blur="isForgotVisible = true"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </p>
        <transition name="fade">
          <button
            v-if="isForgotVisible"
            tabindex="5"
            class="button forgot"
            @click.prevent="$router.push('/recovery')"
          >
            {{ $t("login.forgotPassword") }}
          </button>
        </transition>
      </div>
      <div class="field">
        <label class="checkbox" for="remember-me">
          <input tabindex="3" type="checkbox" id="remember-me" />
          {{ $t("login.rememberMe") }}
        </label>
      </div>
      <p class="control">
        <button
          tabindex="4"
          :class="[
            'button',
            'is-info',
            'is-fullwidth',
            inProgress ? 'is-loading' : '',
          ]"
          @click="login_handler"
        >
          {{ $t("login.login") }}
        </button>

        <a
          style="background-color: white"
          class="button mt-3 is-info is-outlined is-fullwidth"
          @click="sigh_with_google_button"
        >
          <span class="icon">
            <img src="../assets/images/google.256x256.png" />
          </span>
          <span style="color:#3e8fd0">
            {{ $t("login.sign_with_google") }}
          </span>
        </a>
      </p>
    </form>
    <p class="content pt-5">
      <a class="has-text-info" href="#" @click="$router.push('/register')">{{
        $t("login.no_account")
      }}</a>
    </p>
    <p class="content">
      <a class="has-text-info" href="#">{{ $t("login.issues_with_login") }}</a>
    </p>
  </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
.forgot {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  color: hsl(0deg, 0%, 48%);
  background-color: transparent;
}
.forgot:hover {
  color: red;
  color: hsl(0deg, 0%, 21%);
}
</style>
<script>
import router from "@/router/";
import { mapActions, mapState, mapMutations } from "vuex";

const timeOut = 3000;

export default {
  data() {
    return {
      isForgotVisible: true,
      username: process.env.VUE_APP_DEMO_USERNAME || "",
      password: process.env.VUE_APP_DEMO_PASSWORD || "",
      userInputClass: "",
      passwordInputClass: "",
      failed: false,
      inProgress: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  name: "Login",
  methods: {
    ...mapMutations(["SET_CURRENT_LOGIN_FORM"]),
    ...mapActions(["login", "logout", "forgot"]),
    async login_handler(e) {
      e.preventDefault();
      if (!this.username) {
        this.userInputClass = "is-danger";
        this.$showToastWithType(this.$t("login.userNameError"), "error");
        setTimeout(() => {
          this.userInputClass = "";
        }, timeOut);
        return;
      }

      if (!this.password) {
        this.passwordInputClass = "is-danger";
        this.$showToastWithType(this.$t("login.passwordEmptyError"), "error");
        setTimeout(() => {
          this.passwordInputClass = "";
        }, timeOut);
        return;
      }

      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      this.inProgress = true;

      this.login(formData)
        .then(() => {
          this.$showToastWithType(this.$t("login.successMessage"), "success");
        })
        .catch((err) => {
          if (err.data.errorCode == "email_validation") {
            router.push("/validate?email=" + this.username);
          }
          this.$showToastWithType(this.$t("login.loginFailed"), "error");
          this.failed = true;
          setTimeout(() => {
            this.failed = false;
          }, timeOut);
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
    google_sign_in(authCode) {
      console.log("run")
          var formData = new FormData();
          formData.append("oauthCode", authCode);
          formData.append("isOauthLogin", "true");
          this.inProgress = true;

          this.login(formData)
            .then(() => {
              this.$showToastWithType(
                this.$t("login.successMessage"),
                "success"
              );
            })
            .catch((err) => {
              if (err.data.errorCode == "email_validation") {
                router.push("/email-validation?email=" + this.username);
              }
              this.failed = true;
              setTimeout(() => {
                this.failed = false;
              }, timeOut);
            })
            .finally(() => {
              this.inProgress = false;
            });
    },
    sigh_with_google_button(){
      var state = (Math.random() + 1).toString(36).substring(7);
      sessionStorage.setItem("auth0State", state)

      var auth0Url = process.env.VUE_APP_AUTH0_URL 
      var clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
      var redirectUri = process.env.VUE_APP_URL + "/login"
  
      var queryParams = {
        response_type: "code",
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: "openid profile email",
        state: state
      };

      window.location.href = auth0Url+"?"+new URLSearchParams(queryParams).toString()
    }
  },
  mounted() {
    sessionStorage.removeItem("jwt");
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var code = params.get("code")
    var state = params.get("state")
    if (code != null && state != null && state.toString() == sessionStorage.getItem("auth0State")) {
      this.google_sign_in(code)
    }
  },
};
</script>
