<template>
  <div class="wrapper">
    <PaymentForm v-if="isPaymentFormActive"/>

    <div class="table premium">
        <div class="head_tab"><h2>Basic</h2></div>
        <div class="aj_p"><p>{{$t('package.affordable_package')}}</p></div>
        <div class="price-section">
          <div class="price-area">
            <div class="inner-area">
              <span style="font-size:15px;" class="price">$65/30 {{$t('package.day')}}</span>      
              <br>
              <span style="font-size:13px;" class="price">$650/{{$t('package.year')}}</span>      
            </div>
          </div>
        </div>
        <ul class="features">
          <li>
            <span class="list-name">{{$t('package.unlimited_number_of_cameras')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">2 {{$t('package.users')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">1 {{$t('package.project')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">{{$t('package.4k_time_lapse_video')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
              <span class="list-name">{{$t('package.1_photo_in_30_minutes')}}</span>
              <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
              <span class="list-name">Comparison, Sync Zoom</span>
              <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
        </ul>
        <br>
        
        <div class="field">
          {{ $t("package.camera_count") }}
          <p class="control has-icons-left">
            <input
              class="input"
              type="number"
              v-model="basicCameraCount"
              :placeholder="$t('package.camera_count')"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-camera"></i>
            </span>
          </p>

        </div>
        <br>
        <br>
        <div  @click="openPaymentForm('basic', 1,'monthly')" class="btn"><button>{{$t('package.subscribe')}} 30 {{$t('package.day')}}</button></div>
        <br>
        <div  @click="openPaymentForm('basic', 3,'yearly')" class="btn"><button>{{$t('package.subscribe')}} 1 {{$t('package.year')}}</button></div>
        <br>
        
      </div>

    <div class="table premium">
        <div class="head_tab"><h2>Pro</h2></div>
        <div class="aj_p"><p>Full Featured Package</p></div>
        <div class="ribbon"><span>BEST VALUE</span></div>
        <div class="price-section">
          <div class="price-area">
            <div class="inner-area">
              <span style="font-size:15px;" class="price">$95/30 {{$t('package.day')}}</span>      
              <br>
              <span style="font-size:13px;" class="price">$950/{{$t('package.year')}}</span>      
            </div>
          </div>
        </div>
        <ul class="features">
          <li>
            <span class="list-name">{{$t('package.unlimited_number_of_cameras')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">5 {{$t('package.users')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">3 {{$t('package.project')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
            <span class="list-name">{{$t('package.4k_time_lapse_video')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
              <span class="list-name">{{$t('package.1_photo_in_15_minutes')}}</span>
              <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
          <li>
              <span class="list-name">Comparison, Sync Zoom</span>
              <span class="icon check"><i class="fas fa-check"></i></span>
          </li>
        </ul>
        <div class="field">
          {{ $t("package.camera_count") }}
          <p class="control has-icons-left">
            <input
              class="input"
              type="number"
              v-model="proCameraCount"
              :placeholder="$t('package.camera_count')"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-camera"></i>
            </span>
          </p>

        </div>
        <br>
        <br>
        <div  @click="openPaymentForm('pro', 2,'monthly')" class="btn"><button>{{$t('package.subscribe')}} 30 {{$t('package.day')}}</button></div>
        <br>
        <div  @click="openPaymentForm('pro', 2,'yearly')" class="btn"><button>{{$t('package.subscribe')}} 1 {{$t('package.year')}}</button></div>
    </div>
    <div class="table premium">
      <div class="head_tab"><h2>Enterprise</h2></div>
      <div class="aj_p"><p>Great for Professionals</p></div>
      <div class="price-section">
        <div class="price-area">
          <div class="inner-area">
            <span style="font-size:15px;" class="price">$125/30 {{$t('package.day')}}</span>      
            <br>
            <span style="font-size:13px;" class="price">$1250/{{$t('package.year')}}</span>      
          </div>
        </div>
      </div>
      <ul class="features">
        <li>
          <span class="list-name">{{$t('package.unlimited_number_of_cameras')}}</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">{{$t('package.unlimited')}} {{$t('package.users')}}</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">{{$t('package.unlimited')}} {{$t('package.project')}}</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">{{$t('package.8k_time_lapse_video')}}</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
            <span class="list-name">{{$t('package.1_photo_in_10_minutes')}}</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
            <span class="list-name">Comparison, Sync Zoom</span>
            <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
      </ul>
      <div class="field">
          {{ $t("package.camera_count") }}
          <p class="control has-icons-left">
            <input
              class="input"
              type="number"
              v-model="enterpriseCameraCount"
              :placeholder="$t('package.camera_count')"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-camera"></i>
            </span>
          </p>

        </div>
        <br>
        <br>
      <div  @click="openPaymentForm('enterprise', 3,'monthly')" class="btn"><button>{{$t('package.subscribe')}} 30 {{$t('package.day')}}</button></div>
      <br>
      <div  @click="openPaymentForm('enterprise', 3,'yearly')" class="btn"><button>{{$t('package.subscribe')}} 1 {{$t('package.year')}}</button></div>
    </div>

</div>
  </template>

  <script src="https://kit.fontawesome.com/a076d05399.js"></script>

  <script>
import { mapState, mapActions, mapGetters, mapMutations} from "vuex";
import PaymentForm from "@/components/PaymentForm.vue";
 export default {
    name: "PricingCard",
    data() {
      return {
        basicCameraCount: 1,
        proCameraCount: 1,
        enterpriseCameraCount: 1,
        packageId: 0,
        preparePaymentForm: {},
        url:"",
        openPaymentModal:false,
        cc_owner: "",
        card_number: "",
        expiry_month: 0,
        expiry_year: 0,
        cvv: 0,
      };
    },
    components:{
        PaymentForm
    },
    computed: {
      ...mapGetters(["selectedCompany"]),  
      ...mapState({
        user: (state) => state.user,
        companies: (state) => state.companies,
        isPaymentFormActive: (state) => state.paymentForm.isActive,
      }),
    },
    mounted() {
      this.basicCameraCount = 1;
      this.proCameraCount = 1;
      this.enterpriseCameraCount = 1;
      this.SET_PAYMENTFORM_ISACTIVE(false);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_ID(0);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE("");
    },
    methods: {
      ...mapMutations([
      "SET_PAYMENTFORM_CAMERA_COUNT",
      "SET_PAYMENTFORM_ISACTIVE",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_ID",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE",
    ]),
    openPaymentForm(packageName, packageId, packageType) {
      if (packageName == "basic") { 
          console.log("basic geldi")
          this.SET_PAYMENTFORM_CAMERA_COUNT(parseInt(this.basicCameraCount));
      }
      if (packageName == "pro") { 
          console.log("pro geldi")
          this.SET_PAYMENTFORM_CAMERA_COUNT(parseInt(this.proCameraCount));
      }
      if (packageName == "enterprise") { 
          console.log("enterprise geldi")
          this.SET_PAYMENTFORM_CAMERA_COUNT(parseInt(this.enterpriseCameraCount));
      }
    
      this.SET_PAYMENTFORM_ISACTIVE(true);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_ID(packageId);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE(packageType);


    },

     }
  };
  </script>

  <style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  .wrapper{
    max-width: 1090px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .wrapper .table{
    background: #fff;
    width: calc(33% - 20px);
    padding: 30px 30px;
    position: relative;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    text-align: center;
  }
  .table .price-section{
    display: flex;
    justify-content: center;
  }
  .table .price-area{
    width: 120px;
    border-radius: 50%;
    padding: 2px;
  }
  
  .aj_p{
    text-align: center;
  }
  
  p.aj_des {
    color: grey;
    padding-bottom: 30px;
    font-size: 14px;
  }
  
  .table.premium {
    margin: -20px;
  }
  
  .premium_all {
    padding-top: 22px;
  }
  
  .price-area .inner-area{
    width: 100%;
    text-align: center;
    color: #fff;
    position: relative;
  }
  
  .price-area .inner-area .text{
    font-size: 25px;
    font-weight: 400;
    position: absolute;
    top: -10px;
    left: 5px;
  }
  
  .price-area .inner-area .price{
    font-size: 45px;
    font-weight: 400;
    margin-left: 16px;
  }
  
  .table .package-name{
    width: 100%;
    height: 2px;
    margin :35px 0;
    position: relative;
  }
  
  .table .features li{
    margin-bottom: 15px;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .features li .list-name{
    font-size: 17px;
    font-weight: 400;
  }
  
  .features li .icon{
    font-size: 15px;
  }
  
  .features li .icon.check{
    color: #2db94d;
  }
  
  .features li .icon.cross{
    color: #cd3241;
  }
  
  .table .btn{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .table .btn button{
    width: 80%;
    height: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .basic .price-area,
  .basic .inner-area,
  .basic .head_tab h2{
    color:red;
  }
  
  .basic .btn button{
    background: red;
    color: #fff;
  }
  

  .premium .price-area,
  .premium .inner-area,
  .premium .head_tab h2{
    color:#ba24f0;
  }
  
  .premium .btn button{
    background: #ba24f0;
    color: #fff;
  }
  
  
  .ultimate .price-area,
  .ultimate .inner-area,
  .ultimate .head_tab h2{
    color:#f5b55a;
  }
  
  .ultimate .btn button{
    background: #f5b55a;
    color: #fff;
  }
  
  
  @media (max-width:756px){
    .wrapper .table{
      width: 100%;
    }
  
    .table.premium{
      margin: 40px 0px;
    }
    .table.ultimate{
      margin: 0px;
    }
  }
  
  
  .table .ribbon{
    width: 150px;
    height: 150px;
    position: absolute;
    top: -10px;
    left: -10px;
    overflow: hidden;
  }
  
  .table .ribbon::before,
  table .ribbon::after{
    position: absolute;
    content: "";
    z-index: -1;
    display: block;
    border: 7px solid #ba24f0;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  
  .table .ribbon::before{
    top: 0px;
    right: 15px;
  }
  
  .table .ribbon::after{
    bottom: 15px;
    left: 0px;
  }
  
  .table .ribbon span{
    position: absolute;
    top:30px;
    right: 0;
    transform: rotate(-45deg);
    width: 200px;
    background: #ba24f0;
    padding: 10px 0;
    color: #fff;
    text-align: center;
    font-size: 17px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  }
  </style>