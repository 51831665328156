import Vue from "vue";
import axios from "axios";
import router from "@/router/";
import store from "@/store";

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const devInstance = createInstance(API_ENDPOINT);

let requestInterval = null;

devInstance.interceptors.request.use(
  (config) => {

    config.validateStatus = function (status) {
      return status < 500;
    }
  
    
    var jwt = sessionStorage.getItem("jwt");
    if (jwt != null) {
      config.headers["Authorization"] = "Bearer "+jwt;
    }
    config.headers["lang"] = store.state.user.language;
    config.headers["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

devInstance.interceptors.response.use(
  function (response) {
    if (response?.status>299)
    {
    
      store.commit("SET_TOAST_MESSAGE", response?.data.message);
      store.commit("SET_TOAST_STATE", true);
      store.commit("SET_TOAST_TYPE", "error");
      setTimeout(() => {
        store.commit("SET_TOAST_STATE", false);
        store.commit("SET_TOAST_TYPE", "info");
      }, 1500);

    }

    if (response.config.url === "dzi/") {
      clearTimeout(requestInterval);
    }

    store.commit("SET_IS_LOADING", false);
    return response;
  },
  function (err) {

    if (err.response?.status>299)
    {
      store.commit("SET_TOAST_MESSAGE", err.response?.data.message);
      store.commit("SET_TOAST_STATE", true);
      setTimeout(() => {
        store.commit("SET_TOAST_STATE", false);
      }, 1500);
    }


    if (
      router.history.current.name !== "Login" &&
      err.response?.status === 403
    ) {
      switch (err.response.data.code) {
        case "E_BOOKMARK_NO_PERMISSION":
          this.$showToastWithType(this.$i18n.t("message.error.noBookmarkPermission"),"error");
          break;
        case "NO_SELF_ROLE_CHANGE":
          this.$showToastWithType(this.$i18n.t("message.error.noSelfRoleChange"),"error");
          break;
        default:
          this.$showToastWithType(this.$i18n.t("message.error.noPagePermission"),"error");
          // router.push("/");
          break;
      }
    }
    return Promise.reject(err);
  }
);

function createInstance(baseURL) {
  return axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  install() {
    Vue.prototype.$http = devInstance;
  },
};
