import Vue from "vue";
import Vuex from "vuex";

import { mutations } from "@/store/mutations";
import { actions } from "@/store/actions";
import { getters } from "@/store/getters";
import createPersistedState from "vuex-persistedstate";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

var currentDate = new Date();
currentDate = currentDate.setHours(0, 0, 0, 0);

const store = new Vuex.Store({
  plugins: [
    logrocketPlugin,
    createPersistedState({
      key: "natilon",
      // paths: ["selectedDate"],
    }),
  ],
  state: {
    login: {
      currentForm: "LoginForm",
    },
    toast: {
      isActive: false,
      message: "",
      type: "error",
    },
    selectedDate: currentDate,
    selectedSide: "right",
    currentInterval: 0,
    currentPhoto: {
      timestamp: null,
      dziUrl: "",
      file: "",
      bookmark: {
        id: 0,
        title: "",
        type: "personal",
      },
    },
    comparisonPhoto: {
      timestamp: null,
      dziUrl: "",
      file: "",
      bookmark: {
        id: 0,
        title: "",
        type: "personal",
      },
    },
    timelineViewPhotoComponent: "PhotoView",
    isLoading: false,
    user: {
      id: null,
      fullName: "",
      email: "",
      permissions: [],
      isAuthenticated: false,
      language: "tr",
      timezone: "Europe/Istanbul",
    },
    userForm: {
      id: null,
      fullName: "",
      email: "",
      isActive: false,
      roles: [],
      cameras: [],
      projects: [],
    },
    paymentForm: {
      cameraCount: 0,
      selectedPackageId: 0,
      selectedPackageType: "",
      isActive: false,
    },
    companies: [],
    cameras: [],
    projects: [],
    modals: {
      changePassword: {
        isActive: false,
      },
      companySelect: {
        isActive: false,
      },
      videoOrder: {
        isActive: false,
        permission: "order_video",
      },
      videoPlayer: {
        isActive: false,
      },
      videoShare: {
        isActive: false,
        permission: "share_video",
      },
      photoShare: {
        permission: "share_photo",
        isActive: false,
      },
      userDetails: {
        isActive: false,
      },
      bookmarkForm: {
        permission: "edit_bookmark",
        isActive: false,
      },
      languageSelector: {
        isActive: false,
      },
      companyForm: {
        isActive: false,
      },
    },
  },
  mutations,
  actions,
  getters,
});

export default store;
